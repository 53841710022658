// ハンバーガーメニュー
function gnav(toggleElm, contentElm, bgElm) {
  const toggleBtn = document.querySelector(toggleElm);
  const toggleContent = document.querySelector(contentElm);

  toggleBtn.classList.toggle("is-gnav-active");
  document.querySelector("html").classList.toggle("is-gnav-open");

  if (toggleBtn.classList.contains("is-gnav-active")) {
    let createBg = document.createElement("div");
    const bgClass = bgElm.split(".")[1];
    createBg.classList.add(bgClass);
    toggleBtn.parentNode.insertBefore(
      createBg,
      toggleContent.nextElementSibling
    );
  } else {
    document.querySelector(bgElm).remove();
  }
  toggleContent.classList.toggle("is-gnav-active");
  function gnavToggle() {
    toggleContent.classList.toggle("is-gnav-start");
  }
  setTimeout(gnavToggle, 100);
}

// グローバルナビのクリックイベント
const gnavToggleClass = ".js-gnav-toggle"; // トグルボタン
const gnavContentClass = ".gnav"; // ハンバーガーコンテンツ
const gnavBgClass = ".gnav-bg"; // 背景
document.querySelector(gnavToggleClass).addEventListener("click", function () {
  gnav(gnavToggleClass, gnavContentClass, gnavBgClass);
});

// 背景をクリックした場合にメニューを閉じる
document.addEventListener("click", function (event) {
  let gnavBg = gnavBgClass.split(".")[1];
  if (event.target.classList.contains(gnavBg)) {
    gnav(gnavToggleClass, gnavContentClass, gnavBgClass);
  }
});

// ページ内リンクをクリックした場合にメニューを閉じる
document.querySelectorAll('.gnav a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        gnav(gnavToggleClass, gnavContentClass, gnavBgClass);
    });
});
